import type { SecondaryAction } from '@dce-front/hodor-types/modules/action_layout/definitions';
import { addQueryParam } from '@dce-front/onewebapp-utils';
import {
  PRIMARY_ACTION_SUBTYPE,
  PRIMARY_ACTION_TYPE,
} from '../../../../constants/primaryAction';
import { Queries } from '../../../../constants/url';
import type { CustomPrimaryAction } from '../../data/types';
import { SecondaryActionType } from './SecondaryActions/types';

/**
 * Match a formatted date
 * - on HH:MM:SS format
 * - Between 01 and 09 hour
 */
const remainingTimeRegExp = /0.:..:../;

/** Remove the first **0** on remaining time label hours */
export const getFormattedRemainingTimeLabel = (
  remainingTimeLabel: string,
): string => {
  if (remainingTimeLabel.match(remainingTimeRegExp)) {
    return remainingTimeLabel.replace(' 0', ' ');
  }

  return remainingTimeLabel;
};

export type GetPrimaryActionTypes =
  | 'isPlay'
  | 'isDeepLink'
  | 'isUnavailable'
  | 'isTransactional'
  | 'isRental'
  | 'isInternalAnchor'
  | 'isStartOver'
  | 'isLiveOnGoing'
  | 'isNotShaded'
  | 'isLiveShow'
  | 'isStreamOnGoing';

/**
 * Returns the type of PrimaryAction based on given `type` (checked against `PrimaryActionType`) and `subtype` (checked against `PrimaryActionSubtype`)
 *
 * @see {@link https://canal-wiki.canal-plus.com/display/2M/Primary+Actions Primary Actions docs}
 *
 * @example
 * const { isPlay, isTransactional } =
    getPrimaryActionTypes({ type: PrimaryActionType.Play });
 *
 * console.log(isPlay, isTransactional); // true, false
 */
export const getPrimaryActionTypes = ({
  type,
  subtype,
}: {
  /** The primary action `type` */
  type?: string;
  /** The primary action `subtype` */
  subtype?: string;
}): Record<GetPrimaryActionTypes, boolean> => ({
  // PrimaryActionType
  isPlay: type === PRIMARY_ACTION_TYPE.Play,
  isDeepLink: type === PRIMARY_ACTION_TYPE.Deeplink,
  isUnavailable: type === PRIMARY_ACTION_TYPE.Unavailable,
  isTransactional: type === PRIMARY_ACTION_TYPE.Transactional,

  // PrimaryActionSubtype
  isRental: subtype === PRIMARY_ACTION_SUBTYPE.Rental,
  isInternalAnchor: subtype === PRIMARY_ACTION_SUBTYPE.Internal,
  isStartOver: subtype === PRIMARY_ACTION_SUBTYPE.StartOver,
  isLiveOnGoing: subtype === PRIMARY_ACTION_SUBTYPE.LiveOnGoing,
  isNotShaded: subtype === PRIMARY_ACTION_SUBTYPE.NotShaded,
  isLiveShow: subtype === PRIMARY_ACTION_SUBTYPE.LiveTV,
  isStreamOnGoing: subtype === PRIMARY_ACTION_SUBTYPE.StreamOnGoing,
});

type GetEnabledSecondaryActionsTypes = {
  /** `Download To Go` secondary action */
  d2g?: SecondaryAction;
  /** `Personal Video Recorder` secondary action */
  pvr?: SecondaryAction;
};

/**
 * Returns enabled Secondary Actions
 *
 * @see {@link https://canal-wiki.canal-plus.com/display/2M/Secondary+Actions Secondary Actions docs}
 *
 * @example
 * const output = getEnabledSecondaryActions([
 *    { enable: true, type: 'recording', states: [...] },
 *    { enable: false, type: 'download' },
 *  ]);
 *
 * console.log(output); // { pvr: { enable: true, type: 'recording', states: [...] } }
 */
export const getEnabledSecondaryActions = (
  secondaryActions?: SecondaryAction[],
): GetEnabledSecondaryActionsTypes =>
  secondaryActions?.reduce<GetEnabledSecondaryActionsTypes>((acc, action) => {
    if (action.type === SecondaryActionType.Download && action.enable) {
      acc.d2g = action;
    }
    if (action.type === SecondaryActionType.Recording && action.enable) {
      acc.pvr = action;
    }
    return acc;
  }, {}) || {};

/**
 * Get synchronize Url
 *
 * It will add to primaryAction path a refreshRight to force an call pass create token
 *
 * @param primaryAction based on hodor response
 */
export const getSynchronizeUrl = (
  primaryAction: CustomPrimaryAction,
): string => {
  const refreshRightRef = addQueryParam(
    `${window.origin}${primaryAction.onClick?.path}`,
    Queries.RefreshRight,
    'true',
  );
  return refreshRightRef;
};
