import classNames from 'classnames';
import type { JSX } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import Linker from '../../../../../components/Linker/Linker';
import { getPublicTimeout } from '../../../../../helpers/time/time-helper';
import {
  pathnameSelector,
  routerOnClickSelector,
  routingContextSelector,
} from '../../../../../store/slices/routing-selectors';
import { TABS_PANEL_ID_PREFIX, TABS_TAB_ID_PREFIX } from '../constants';
import styles from './Tab.css';

export type TabProps = {
  active: boolean;
  index: number;
  displayName?: string;
  onClick: () => void;
  path?: string;
  tabsListRef: React.RefObject<HTMLUListElement | null>;
};

const SCROLL_MARGIN_LEFT_OFFSET = 100;

/**
 * Links to relevant Tab path while maintaining Tab parent's context data
 */
function Tab({
  displayName,
  active,
  onClick,
  path,
  tabsListRef: { current: tabsListRef },
  index,
}: TabProps): JSX.Element {
  const tabParentOnClick = useSelector(routerOnClickSelector);
  const pathname = useSelector(pathnameSelector);
  const context = useSelector(routingContextSelector);

  const tabRef = useRef<HTMLLIElement>(null);
  const tabLeftOffset = tabRef?.current?.offsetLeft;

  // Horizontally scrolls into view an overflowed/hidden active tab
  if (active && tabsListRef && typeof tabLeftOffset === 'number') {
    setTimeout(
      () =>
        tabsListRef.scrollTo({
          left: tabLeftOffset - SCROLL_MARGIN_LEFT_OFFSET,
          behavior: 'smooth',
        }),
      getPublicTimeout('900ms'),
    );
  }

  return (
    <li
      className={classNames(styles.tab, { [styles['tab--active']!]: active })}
      ref={tabRef}
      role="tab"
      aria-selected={active}
      aria-controls={`${TABS_PANEL_ID_PREFIX}${index + 1}`}
      id={`${TABS_TAB_ID_PREFIX}${index + 1}`}
    >
      <Linker
        onClick={onClick}
        title={displayName}
        data={{ mainOnClick: { ...tabParentOnClick, path }, context }}
        data-navigateonfocus={!active}
        replace
      >
        {!$_BUILD_RENDERMODE_CSR && active && pathname === path ? (
          <h1>{displayName}</h1>
        ) : (
          displayName
        )}
      </Linker>
    </li>
  );
}

export default Tab;
