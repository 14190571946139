import loadable from '@loadable/component';
import Spinner from '../../../../components/Spinner/Spinner';

const LoadableMoreInfos = loadable(
  () => import('./components/MoreInfosContainer'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableMoreInfos;
