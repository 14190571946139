import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  areAnimationsMutedSelector,
  areAnimationsTemporarilyUnmutedSelector,
  isTrailerMutedSelector,
} from '../../../store/slices/user-selectors';

const getHasUserBeenActive = () => {
  try {
    return navigator.userActivation?.hasBeenActive;
  } catch (error) {
    return false;
  }
};

/**
 * Helper to retrieve mute value by priority (temporarilyValue > preferredUnmute > settings value)
 */
const getIsMuted = ({
  preferredUnmuted,
  isMutedInSettings,
  temporarilyUnmuted,
  hasUserBeenActive,
}: {
  /**
   * Should prefer sound for the current feature if possible
   * (e.g: Short Video List, whenever we enter, it should have sound if possible)
   */
  preferredUnmuted: boolean;
  /**
   * The value of sound in settings, saved in local storage of the device
   */
  isMutedInSettings: boolean;
  /**
   * The value of sound if the user changed it during his session, saved in redux
   */
  temporarilyUnmuted?: boolean;
  /**
   * Has the user interacted with the page
   */
  hasUserBeenActive: boolean;
}): boolean => {
  // if the user unmuted an embedded video
  if (temporarilyUnmuted) {
    return false;
  }
  // Only on Web
  // if the feature favored sound on and the user did not mute an embedded video
  if (preferredUnmuted && hasUserBeenActive && temporarilyUnmuted !== false) {
    return false;
  }
  // return the value from settings
  return isMutedInSettings;
};

/**
 * Users can mute or unmute the animations
 * This value is stored in local storage and redux store
 */
export const useAreAnimationsMuted = (
  preferredUnmuted = false,
): {
  /**
   * Are all video animations (excluded the trailer) currently muted
   * by priority (temporarilyValue > preferredUnmute > settings value)
   */
  areAnimationsMuted: boolean;
  /**
   * Are all video animations (excluded the trailer) muted in the user settings saved in local storage
   */
  areAnimationsMutedInSettings: boolean;
  /**
   * Is Trailer feature currently muted
   * by priority (temporarilyValue > preferredUnmute > settings value)
   */
  isTrailerMuted: boolean;
  /**
   * Is Trailer feature muted in the user settings saved in local storage
   */
  isTrailerMutedInSettings: boolean;
} => {
  // Only take the first value into account to avoid unmuting the video on user interaction
  const [hasUserBeenActive] = useState<boolean>(() => getHasUserBeenActive());
  const areAnimationsTemporarilyUnmuted = useSelector(
    areAnimationsTemporarilyUnmutedSelector,
  );

  const areAnimationsMutedInSettings = useSelector(areAnimationsMutedSelector);
  const isTrailerMutedInSettings = useSelector(isTrailerMutedSelector);

  return {
    areAnimationsMuted: getIsMuted({
      preferredUnmuted,
      isMutedInSettings: areAnimationsMutedInSettings,
      temporarilyUnmuted: areAnimationsTemporarilyUnmuted,
      hasUserBeenActive: hasUserBeenActive,
    }),
    areAnimationsMutedInSettings,
    isTrailerMuted: getIsMuted({
      preferredUnmuted,
      isMutedInSettings: isTrailerMutedInSettings,
      temporarilyUnmuted: areAnimationsTemporarilyUnmuted,
      hasUserBeenActive: hasUserBeenActive,
    }),
    isTrailerMutedInSettings,
  };
};
