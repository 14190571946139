import loadable from '@loadable/component';
import Spinner from '../../../../../../components/Spinner/Spinner';

const LoadableEpisodesListContainer = loadable(
  () => import('./EpisodesListContainer'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableEpisodesListContainer;
