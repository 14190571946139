import { mapStaticKey } from '@canalplus/mycanal-util-react';
import type { SecondaryAction } from '@dce-front/hodor-types/modules/action_layout/definitions';
import type { JSX } from 'react';
import { useSelector, useStore } from 'react-redux';
import { match } from 'ts-pattern';
import { isMoods, Moods } from '../../../../../constants/mood';
import { isD2GSupported } from '../../../../../helpers/downloadToGo/downloadToGo-helper';
import { useInvariantSelector } from '../../../../../helpers/hooks/useInvariantSelector';
import {
  getFeatureToggleD2G,
  hostnameSelector,
  offerLocationSelector,
  renderSourceSelector,
} from '../../../../../store/slices/application-selectors';
import { authenticatedSelector } from '../../../../../store/slices/user-selectors';
import MoodModalButton from '../../../../../templates/DetailV5/components/ActionLayout/MoodModalButton/MoodModalButton';
import { useDetailContext } from '../../../../../templates/DetailV5/data/provider';
import { ConnectButton } from './ConnectButton/ConnectButton';
import D2G from './D2G/D2G';
import PlaylistButton from './PlaylistButton/PlaylistButton';
import RecordingButton from './RecordingButton/RecordingButton';
import styles from './SecondaryActions.css';
import SharingButton from './SharingButton/SharingButton';
import { isSecondaryActionType, SecondaryActionType } from './types';
import { useSigninRedirect } from '../../../../../helpers/pass/useSigninRedirect';
import type { IState } from '../../../../../store/types/State-type';
import { useRedirectToTvPageAfterSignIn } from '../../../../../helpers/pass/useRedirectToTvPageAfterSignIn';
import { isClientSide } from '@dce-front/onewebapp-utils';

export type ISecondaryActionsProps = {
  secondaryActions: SecondaryAction[];
  hasPushOpinion?: boolean;
  isFetchingPushOpinion?: boolean;
};

function SecondaryActions({
  secondaryActions,
  hasPushOpinion,
  isFetchingPushOpinion,
}: ISecondaryActionsProps): JSX.Element | null {
  const isClient = useSelector(renderSourceSelector) === 'client';
  const content = useDetailContext();
  const isFeatD2G = useInvariantSelector(getFeatureToggleD2G);
  const offerLocation = useInvariantSelector(offerLocationSelector);
  const shouldRenderD2G = isClient && isD2GSupported(offerLocation);
  const isLoggedIn = useSelector(authenticatedSelector);

  const store = useStore<IState>();
  const state = store.getState();
  const hostname = hostnameSelector(state);

  const signInRedirect = useSigninRedirect();
  const redirectToTvPageAfterSignIn = useRedirectToTvPageAfterSignIn();

  const { title, contentID } = content.detail;

  const filterSecondaryActions = (
    actions: SecondaryAction[],
  ): SecondaryAction[] => {
    let filteredActions: SecondaryAction[] = [];
    filteredActions = actions.filter(
      ({ type, enable, userRating, startTime, endTime, epgID }) =>
        isSecondaryActionType(type) &&
        ((type === SecondaryActionType.Download &&
          !$_BUILD_RENDERMODE_CSR &&
          shouldRenderD2G &&
          isFeatD2G &&
          enable &&
          contentID) ||
          (type === SecondaryActionType.Playlist && contentID) ||
          (type === SecondaryActionType.Opinion && userRating && contentID) ||
          (type === SecondaryActionType.Authentication && !isLoggedIn) ||
          (type === SecondaryActionType.Share &&
            !$_BUILD_RENDERMODE_CSR &&
            title) ||
          (type === SecondaryActionType.Recording &&
            $_BUILD_RENDERMODE_CSR &&
            startTime &&
            endTime &&
            epgID)),
    );
    return filteredActions;
  };

  const secondaryActionsFiltered = mapStaticKey(
    filterSecondaryActions(secondaryActions),
    'type',
  );

  const handleSignin = (redirectPath: string | undefined) => {
    if ($_BUILD_RENDERMODE_CSR) {
      redirectToTvPageAfterSignIn(redirectPath);
    }

    const redirectUri = isClientSide()
      ? window.location.origin
      : `https://${hostname}`;

    signInRedirect(`${redirectUri}${redirectPath}`);
  };

  return secondaryActionsFiltered.length ? (
    <ul className={styles.secondaryActions}>
      {secondaryActionsFiltered.map(
        ({
          type,
          enable,
          isInPlaylist,
          userRating,
          sharingURL = '',
          states = [],
          hash,
          epgID,
          startTime,
          endTime,
          onClick,
        }) => {
          return match(type)
            .with(SecondaryActionType.Download, () => (
              <li key={hash}>
                <D2G contentID={contentID || ''} statesLabel={states} />
              </li>
            ))
            .with(SecondaryActionType.Playlist, () => (
              <li key={hash}>
                <PlaylistButton
                  customClassBadgeV5={styles.secondaryActions__playlistBtnTv}
                  autoAddToPlaylist={content.currentPage?.addToPlaylist}
                  isInPlaylist={!!isInPlaylist}
                  statesLabel={states}
                  isDisabled={!enable}
                  contentID={contentID || ''}
                />
              </li>
            ))
            .with(SecondaryActionType.Opinion, () => (
              <li key={hash}>
                <MoodModalButton
                  userRating={
                    isMoods(userRating) ? userRating : Moods.NoOpinion
                  }
                  statesLabel={states}
                  hasPushOpinion={hasPushOpinion}
                  isFetchingPushOpinion={isFetchingPushOpinion}
                  isMediaCardV5
                  isDisabled={!enable}
                  contentID={contentID}
                  customClassBadgeV5={styles.secondaryActions__moodBtnTv}
                />
              </li>
            ))
            .with(SecondaryActionType.Authentication, () => (
              <li key={hash}>
                <ConnectButton
                  label={states[0]?.label}
                  onClick={() => handleSignin(onClick?.redirectPath)}
                />
              </li>
            ))
            .with(SecondaryActionType.Share, () => (
              <li key={hash}>
                <SharingButton
                  isDisabled={!enable}
                  label={states[0]?.label}
                  sharingUrl={sharingURL}
                />
              </li>
            ))
            .with(SecondaryActionType.Recording, () => (
              // epgID, startTime & endTime have already been checked to be defined in `filterSecondaryActions`
              <li key={hash}>
                <RecordingButton
                  contentId={contentID}
                  epgId={epgID || 0}
                  startDate={startTime || 0}
                  endDate={endTime || 0}
                  isDisabled={!enable}
                  statesLabel={states}
                  customClassBadgeV5={styles.secondaryActions__recordingBtnTv}
                />
              </li>
            ))
            .otherwise(() => null);
        },
      )}
    </ul>
  ) : null;
}

export default SecondaryActions;
