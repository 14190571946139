import classNames from 'classnames';
import type { JSX } from 'react';
import { RatingsTeleramaSvg } from '../../icons/icons';
import {
  MAX_RATING_ICONS_BY_VARIANT,
  type RatingsProps,
  RatingsVariant,
} from './Ratings.types';
import RatingsStar from './RatingsStar/RatingsStar';
import { RatingsStarFill } from './RatingsStar/RatingsStar.types';

/**
 * Determines the fill type for a star in the Ratings component based on the rating variant, rating value and ratingIndex.
 *
 * @param ratingVariant - The variant of the Ratings component. Can be either 'primary' or 'secondary'.
 * @param rating - Rating value. Maximum rating for Primary is 5, for Secondary is 4.
 * @param ratingIndex - the zero-index for current start being evaluated.
 *
 * @returns - The fill type for the star. It can be either 'full', 'half', or 'empty'.
 *
 * @remarks
 * The 'half' fill type is valid for Primary variant only
 *
 */
export const getRatingsStarFill = (
  ratingVariant: `${RatingsVariant.Primary | RatingsVariant.Secondary}`,
  rating: number,
  ratingIndex: number,
) => {
  const integerRating = Math.floor(rating);
  const decimalRating =
    ratingVariant === 'primary' ? rating - integerRating : 0;

  if (ratingIndex < integerRating) {
    return RatingsStarFill.Full;
  }
  if (ratingIndex === integerRating && decimalRating) {
    return RatingsStarFill.Half;
  }
  return RatingsStarFill.Empty;
};

/**
 * The Ratings component displays user feedback scores visually
 *
 * This component is non-interactive.
 *
 * Ratings has following variants:
 *
 * - `primary`: Yellow star icons. Maximum rating is 5. Allow half rating.
 * - `secondary`: Red star icons. Maximum rating is 4. Allow integer rating only.
 * - `tertiary`: T shape icons. Maximum rating is 4. Allow integer rating only.
 *
 * Ratings component's height is based on the height props or the height of container
 *
 * @example
 *
 * ```tsx
 * <Ratings height={24} ratings={4.5} variant="primary" />
 * ```
 */
export function Ratings({
  variant,
  rating,
  height,
  className,
  'data-testid': dataTestId,
}: RatingsProps): JSX.Element {
  return (
    <div
      style={{ height }}
      className={classNames(
        'flex h-full w-full',
        variant !== 'tertiary'
          ? 'space-x-dt-spacing-25'
          : '-space-x-dt-spacing-25',
        className,
      )}
      data-testid={dataTestId}
    >
      {Array.from({ length: MAX_RATING_ICONS_BY_VARIANT[variant] }).map(
        (_, ratingIndex) => {
          if (variant === 'tertiary') {
            return (
              ratingIndex < rating && (
                <span className="flex" key={`ratings-${ratingIndex + 1}`}>
                  <RatingsTeleramaSvg />
                </span>
              )
            );
          }
          return (
            <RatingsStar
              key={`ratings-${ratingIndex + 1}`}
              variant={variant}
              fill={getRatingsStarFill(variant, rating, ratingIndex)}
            />
          );
        },
      )}
    </div>
  );
}
