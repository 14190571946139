import { Button, ButtonIcon, WebMenuAccountSvg } from '@dce-front/dive';

type ConnectButtonProps = {
  label?: string;
  onClick?: () => void;
};

export const ConnectButton = ({ label, onClick }: ConnectButtonProps) => {
  return $_BUILD_RENDERMODE_CSR ? (
    <Button
      data-testid="connect-button-tv"
      icon={<WebMenuAccountSvg />}
      iconPosition="left"
      id="default"
      variant="primary"
      width="full"
      font="hind"
      onClick={onClick}
    >
      {label}
    </Button>
  ) : (
    <ButtonIcon
      data-testid="connect-button"
      aria-label={label}
      icon={<WebMenuAccountSvg />}
      label={label}
      onClick={onClick}
    />
  );
};
