import type { ApiV2Cover } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import { DIMENSIONS } from '@dce-front/onewebapp-dive-utils';
import { getObjectKeys, Ratio } from '@dce-front/onewebapp-utils';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import ThumborMediaImage from '../../../../components/ThumborMediaImage/ThumborMediaImage';
import { Video } from '../../../../components/Video';
import { ScaleUp } from '../../../../components/Video/components/PlayerContainer/PlayerContainer';
import { useAreAnimationsAutoplay } from '../../../../components/Video/hooks/useAreAnimationsAutoplay';
import { useAreAnimationsMuted } from '../../../../components/Video/hooks/useAreAnimationsMuted';
import { useGetVideoContentFromUrlMedia } from '../../../../components/Video/hooks/useGetVideoContentFromUrlMedia';
import { useIntersectionObserverForVideo } from '../../../../components/Video/hooks/useIntersectionObserverForVideo';
import { useInvariantSelector } from '../../../../helpers/hooks/useInvariantSelector';
import { getFeatureToggleTrailerPreview } from '../../../../store/slices/application-selectors';
import styles from './Cover.css';

const cx = classNames.bind(styles);

export type CoverProps = {
  cover: ApiV2Cover;
  isVisible?: boolean;
  title?: string;
};

function Cover({ cover, isVisible = true, title }: CoverProps): JSX.Element {
  const { compactImage, regularImage, trailer } = cover;
  const { isTrailerMuted } = useAreAnimationsMuted();
  const { isTrailerAutoplay } = useAreAnimationsAutoplay();
  const hasTrailer =
    useInvariantSelector(getFeatureToggleTrailerPreview) &&
    Boolean(trailer?.URLMedias) &&
    isTrailerAutoplay;
  const compactImgRatio = compactImage?.imageRatio || Ratio.Ratio34;
  const regularImgRatio = regularImage?.imageRatio || Ratio.Ratio169;
  const { intersectionRef, isRootRefIntersecting } =
    useIntersectionObserverForVideo();
  const videoContent = useGetVideoContentFromUrlMedia({
    urlMedias: cover.trailer?.URLMedias,
  });

  const coverImage = (
    <ThumborMediaImage
      url={regularImage?.URLImage}
      urlMobile={compactImage?.URLImage}
      alt={title}
      dimensions={{
        default: getObjectKeys(DIMENSIONS.COVER_DETAIL_V5).includes(
          regularImgRatio as keyof typeof DIMENSIONS.COVER_DETAIL_V5,
        )
          ? DIMENSIONS.COVER_DETAIL_V5[
              regularImgRatio as keyof typeof DIMENSIONS.COVER_DETAIL_V5
            ]
          : DIMENSIONS.COVER_DETAIL_V5[169],
        mobile: getObjectKeys(DIMENSIONS.COVER_DETAIL_V5).includes(
          compactImgRatio as keyof typeof DIMENSIONS.COVER_DETAIL_V5,
        )
          ? DIMENSIONS.COVER_DETAIL_V5[
              compactImgRatio as keyof typeof DIMENSIONS.COVER_DETAIL_V5
            ]
          : DIMENSIONS.COVER_DETAIL_V5[34],
      }}
    />
  );

  return (
    <div
      className={cx(
        'cover',
        `cover--compact-ratio-${compactImgRatio}`,
        `cover--regular-ratio-${regularImgRatio}`,
      )}
      data-testid="detailv5-cover"
      ref={intersectionRef}
    >
      {hasTrailer && videoContent ? (
        <Video
          // Key is necessary to force the re-render of the video component when
          // the trailer changes. e.g. When DetailV5 immersive opens another
          // immersive without unMounting the previous one
          key={cover.trailer?.URLMedias}
          cover={coverImage}
          destroyOnIsMainPlayerOpen
          hasFullFrameIndicator={$_BUILD_RENDERMODE_CSR}
          isMuted={isTrailerMuted}
          isVisible={isVisible && isRootRefIntersecting}
          scaleUp={ScaleUp.Large}
          videoContent={videoContent}
        />
      ) : (
        coverImage
      )}
    </div>
  );
}

export default Cover;
