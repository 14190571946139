import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset as offsetFloatingUI,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames';
import type { JSX } from 'react';
import { cloneElement, useRef, useState } from 'react';
import { useDiveContext } from '../../context';
import type { TooltipProps } from './Tooltip.types';
import { tooltipViewCVA } from './TooltipView.cva';
import {
  ARROW_HEIGHT,
  ARROW_OFFSET,
  ARROW_WIDTH,
  TRANSITIONS,
  TRANSITION_DURATIONS,
} from './constants';

/**
 * Maximum length of content of tooltip to determine if tooltip is single or multiple lines.
 * To style the link inline (if content is single line)
 * Or the link is separate line (if content is multiple lines)
 */
const TOOLTIP_CONTENT_LONG_LENGTH_THRESHOLD = 50;

/**
 * A Tooltip is a small text that appears when the user hovers over an element.
 * It is used to explain an interface element or feature to the user.
 *
 * @example
 *
 * ```tsx
 * <Tooltip content="description" position='top'>
 *    <p>text</p>
 * </Tooltip>
 * ```
 */
export const Tooltip = function Tooltip({
  content,
  position = 'top',
  className,
  children,
  isVisible = false,
  showOnHover = true,
  width = 'fluid',
  transition = 'move-fade',
  link,
  'data-testid': dataTestId,
  propRef,
}: TooltipProps): JSX.Element {
  const { isTv } = useDiveContext();
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen || isVisible,
    onOpenChange: setIsOpen,
    placement: position,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offsetFloatingUI({
        mainAxis: ARROW_OFFSET.px + ARROW_HEIGHT.px,
        alignmentAxis: -ARROW_HEIGHT.px,
      }),
      flip(),
      // eslint-disable-next-line react-compiler/react-compiler
      arrow({ element: arrowRef }),
    ],
  });
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  const { isMounted, styles: stylesTransition } = useTransitionStyles(context, {
    duration: TRANSITION_DURATIONS,
    initial: ({ side }) => {
      return TRANSITIONS[transition][side];
    },
  });

  // Event listeners to change the open state
  const hover = useHover(context, { enabled: showOnHover });
  const focus = useFocus(context, { enabled: showOnHover });
  const dismiss = useDismiss(context, { enabled: showOnHover });
  const role = useRole(context, { role: 'tooltip' });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const isLongContent =
    typeof content === 'string' &&
    content.length >= TOOLTIP_CONTENT_LONG_LENGTH_THRESHOLD;

  if (isTv || !content) {
    return children;
  }

  const visibleTooltip = (isOpen || isVisible) && isMounted;

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({
          ref,
          ...children.props,
        }),
      )}

      <FloatingPortal>
        {visibleTooltip && (
          <div
            // by default use a z-index 2000 (arbitrary value) to be sure the tooltip is on top of all the elements
            className={classNames('z-[2000]', className)}
            ref={refs.setFloating}
            style={floatingStyles}
            data-testid={dataTestId}
            {...getFloatingProps()}
          >
            <div
              style={stylesTransition}
              className={tooltipViewCVA({
                width,
                isLongContent,
              })}
            >
              <span>{content}</span>
              {link &&
                cloneElement(link, {
                  className: classNames({
                    'mt-dt-spacing-100': isLongContent,
                    'ms-dt-spacing-100': !isLongContent,
                  }),
                  hasIcon: isLongContent,
                })}
              <FloatingArrow
                className="fill-dt-theme-surface-tooltip-tooltip"
                width={ARROW_WIDTH.px}
                height={ARROW_HEIGHT.px}
                ref={arrowRef}
                context={context}
              />
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
};
