import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadableSportVitrine = loadable(
  () => import('./components/SportVitrine'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableSportVitrine;
