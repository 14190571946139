import { ModalV2 } from '@canalplus/mycanal-sharedcomponent';
import { KEY_BACK, useKeyCatcher } from '@dce-front/one-navigation';
import type { JSX } from 'react';
import { LAYER_IMMERSIVE_MOREINFOS } from '../../../../../helpers/oneNavigation/layers';
import LoadableMoreInfos from '../../../../../templates/DetailV5/components/MoreInfos';

export type MoreInfosModalProps = {
  setOpenMoreInfosModal: (isMoreInfosModalOpen: boolean) => void;
  URLPage: string;
};

function MoreInfosModal({
  setOpenMoreInfosModal,
  URLPage,
}: MoreInfosModalProps): JSX.Element {
  const handleClose = () => setOpenMoreInfosModal(false);
  useKeyCatcher(KEY_BACK, handleClose, LAYER_IMMERSIVE_MOREINFOS);

  return (
    <ModalV2
      id="modal_moreinfos" // Used on scroll.ts TV navigation
      isTvDevice={$_BUILD_RENDERMODE_CSR}
      hideCloseButton={$_BUILD_RENDERMODE_CSR}
      onBack={handleClose}
      onClose={handleClose}
      size="fullscreen"
    >
      <LoadableMoreInfos url={URLPage} />
    </ModalV2>
  );
}

export default MoreInfosModal;
