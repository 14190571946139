import type { ApiV2DetailTab } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import {
  Binder,
  enter,
  memory,
  spatial,
  useLayer,
} from '@dce-front/one-navigation';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Spinner from '../../../../components/Spinner/Spinner';
import { isPointerVisible } from '../../../../helpers/binder/pointer';
import { useAppLocation } from '../../../../helpers/hooks/reactRouter';
import { scroll, snapToTop } from '../../../../helpers/oneNavigation/scroll';
import { hideTabHorizontalNavigation } from '../../../../helpers/oneNavigation/tabs';
import { getDefaultTabIndex } from '../../data/helper';
import TabPanel from './TabPanel/TabPanel';
import styles from './Tabs.css';
import TabsList from './TabsList/TabsList';
import { usePrefetchDetailTabs, useShowTabsContent } from './hooks';

const cx = classNames.bind(styles);

export type TabsProps = {
  isImmersive?: boolean;
  isActionLayoutLoading?: boolean;
  setTabIndexToScroll: React.Dispatch<React.SetStateAction<number | null>>;
  tabIndexToScroll: number | null;
  tabs: ApiV2DetailTab[];
};

function Tabs({
  isImmersive,
  isActionLayoutLoading = false,
  setTabIndexToScroll,
  tabs,
  tabIndexToScroll,
}: TabsProps): JSX.Element {
  const { pathname } = useAppLocation();
  const tabsAnchorRef = useRef<HTMLDivElement>(null);

  // Computes the default tab index to focus
  const { defaultTabIndex, isTabTargetedByUrl } = useMemo(
    () => getDefaultTabIndex(tabs, pathname),
    [tabs, pathname],
  );

  const layer = useLayer();
  const showTabContent = useShowTabsContent(isImmersive);
  const defaultTab = tabs[defaultTabIndex];
  // If we are on the root url of the detailPage (without specific tab url),
  // the main meta of the page must not be overridden by that of the default tab.
  const disableMetaUpdate = !isTabTargetedByUrl;

  const [currentTab, setCurrentTab] = useState<ApiV2DetailTab | undefined>(
    defaultTab,
  );
  const [isTabVisible, setIsTabVisible] = useState<boolean>(true);

  // Generates a new one-navigation middleware each time the defaultIndex or pathname changes
  const middleware = useMemo(() => {
    return [
      hideTabHorizontalNavigation(setIsTabVisible),
      scroll([snapToTop]),
      enter({ forceFocusIndex: defaultTabIndex, shouldForceFocusOnce: true }),
      memory(),
      spatial(),
    ];
  }, [defaultTabIndex, setIsTabVisible]);

  const scrollToTabPanel = useCallback(() => {
    if ($_BUILD_RENDERMODE_CSR && !isPointerVisible()) {
      /* immediately scroll to it without smoothness, using oneNav scroll middleware */
      layer.getBinderById('tabs')?.focusByIndex(0);
    } else {
      /* .scrollTo() prevents bug where the scroll would stop midway */
      const container = document.querySelector('#immersive') || window;
      requestAnimationFrame(() =>
        container.scrollTo({
          top: tabsAnchorRef.current?.offsetTop,
          behavior: 'smooth',
        }),
      );
    }
    setTabIndexToScroll(null);
  }, [layer, tabsAnchorRef, setTabIndexToScroll]);

  useEffect(() => {
    // defaultTab changes each time the user navigates through the immersive
    if (defaultTab) {
      setIsTabVisible(true);
      setCurrentTab(defaultTab);
    }
  }, [defaultTab]);

  useEffect(() => {
    // allows the user to navigate from the top of the detail page to the tabulation using the anchor given in the onClick of primaryActions
    if (tabIndexToScroll !== null && tabIndexToScroll >= 0) {
      setIsTabVisible(true);
      setCurrentTab(tabs[tabIndexToScroll]);
      scrollToTabPanel();
    }
  }, [tabIndexToScroll, tabs, scrollToTabPanel]);

  const switchTab = useCallback(
    (tab: ApiV2DetailTab, index = 0) => {
      setIsTabVisible(true);
      setCurrentTab(tab);
      if (!$_BUILD_RENDERMODE_CSR || isPointerVisible()) {
        setTabIndexToScroll(index);
      }
    },
    [setTabIndexToScroll],
  );

  usePrefetchDetailTabs({ tabs, path: pathname });

  return (
    <>
      <div
        id="tabs-anchor"
        ref={tabsAnchorRef}
        className={cx('tabsAnchor', {
          'tabsAnchor--immersive': isImmersive,
        })}
      />
      <nav
        className={cx('tabsListWrapper', 'tabsWrapper', {
          'tabsListWrapper--immersive': isImmersive,
        })}
      >
        <Binder
          binderId="tabs"
          middleware={middleware}
          className={cx('tabsWrapper__content', {
            'tabsWrapper__content--fadeIn': !isActionLayoutLoading,
          })}
        >
          <TabsList
            tabs={tabs}
            displayNameActivated={isTabVisible ? currentTab?.displayName : ''}
            switchTab={switchTab}
          />
        </Binder>
      </nav>
      {!!currentTab?.URLPage && (
        <div
          data-testid="tabs-main-panel"
          className={cx('tabsPanelWrapper', 'tabsWrapper', {
            tabsPanelWrapper__hidden: !isTabVisible,
          })}
        >
          {showTabContent && (
            <div
              className={cx('tabsWrapper__content', {
                'tabsWrapper__content--fadeIn': !isActionLayoutLoading,
              })}
            >
              <TabPanel
                disableMetaUpdate={disableMetaUpdate}
                displayTemplate={currentTab.displayTemplate}
                URLPage={currentTab.URLPage}
                onClickParameters={currentTab?.parameters}
                index={defaultTabIndex}
              />
            </div>
          )}
        </div>
      )}
      {!isTabVisible && <Spinner />}
    </>
  );
}

export default memo(Tabs);
