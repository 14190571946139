import { ImageType } from '@canalplus/mycanal-sharedcomponent';
import { DIMENSIONS } from '@dce-front/onewebapp-dive-utils';
import type { JSX } from 'react';
import { useInvariantSelector } from '../../../../../helpers/hooks/useInvariantSelector';
import { applicationResizeSelector } from '../../../../../store/slices/application-selectors';

export type URLLogoProps = {
  URLLogoChannel?: string;
  URLLogoBrand?: string;
};

/**
 * URLLogo Component
 *
 * @param URLLogoChannel                 Logo for Channel
 * @param URLLogoBrand                   Logo for Brand
 */
function URLLogo({ URLLogoChannel, URLLogoBrand }: URLLogoProps): JSX.Element {
  const resizeMode = useInvariantSelector(applicationResizeSelector);

  return (
    <>
      {URLLogoChannel && (
        <ImageType
          URLImage={{ default: URLLogoChannel }}
          dimensions={DIMENSIONS.LOGO_V5}
          isTvDevice={$_BUILD_RENDERMODE_CSR}
          resizeMode={resizeMode}
        />
      )}
      {URLLogoBrand && (
        <ImageType
          URLImage={{ default: URLLogoBrand }}
          dimensions={DIMENSIONS.LOGO_V5}
          isTvDevice={$_BUILD_RENDERMODE_CSR}
          resizeMode={resizeMode}
        />
      )}
    </>
  );
}

export default URLLogo;
