import type { OfferLocation } from '@canalplus/sdk-core';
import { getPublicConfig } from '../config/config-helper';
import { isWindows } from '../userAgent/userAgent-helper';

export const isD2GSupported = (currentOfferLocation: OfferLocation): boolean =>
  (getPublicConfig().DOWNLOADTOGO.ALLOWED_OFFER_LOCATION.includes(
    currentOfferLocation,
  ) &&
    isWindows()) ||
  !isWindows();
