export type Metadata = PersonalityMetadata | ProductionNationalityMetadata;

export type PersonalityMetadata = {
  prefix?: string;
  personalitiesList: Personality[];
};

export type ProductionNationalityMetadata = {
  prefix: string;
  productionNationalitiesList: ProductionNationality[];
};

export type Personality = {
  title?: string;
  onClick?: {
    displayName?: string;
    displayTemplate: string;
    URLPage?: string;
    path?: string;
    isKids?: boolean;
  };
};

export type ProductionNationality = {
  title: string;
};

export const isPersonalityMetadata = (
  metadata: Metadata,
): metadata is PersonalityMetadata => {
  return (metadata as PersonalityMetadata)?.personalitiesList !== undefined;
};

export const isProductionNationalityMetadata = (
  metadata: Metadata,
): metadata is ProductionNationalityMetadata => {
  return (
    (metadata as ProductionNationalityMetadata)?.productionNationalitiesList !==
    undefined
  );
};
