import {
  ButtonIcon,
  DetailPagePlaylistOffSvg,
  DetailPagePlaylistOnSvg,
  Tooltip,
} from '@dce-front/dive';
import type { SecondaryActionState } from '@dce-front/hodor-types/modules/action_layout/definitions';
import classNames from 'classnames';
import { debounce } from 'es-toolkit';
import type { JSX } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Badge from '../../../../../../components/Badge/Badge';
import {
  ADDED_TO_PLAYLIST,
  PlaylistStateId,
  REMOVED_FROM_PLAYLIST,
} from '../../../../../../constants/playlist';
import { useAppDispatch } from '../../../../../../helpers/hooks/useAppDispatch';
import { useSendTrackingOnCTAEvent } from '../../../../../../helpers/tracking/useSendTrackingOnCTAEvent';
import { useTranslation } from '../../../../../../lang';
import { userListService } from '../../../../../../services/PlaylistService';
import { setPersoUpdated } from '../../../../../../store/slices/immersive';
import {
  authenticatedSelector,
  hasUserDataCollectedSelector,
} from '../../../../../../store/slices/user-selectors';
import stylesDetail from './../../../DetailV5.css';
import styles from './Playlist.css';
import { useAutoAddToPlaylist } from './hooks/useAutoAddToPlaylist';

export type PlaylistButtonProps = {
  autoAddToPlaylist?: boolean;
  contentID: string;
  isDisabled?: boolean;
  isInPlaylist: boolean;
  customClassBadgeV5?: string;
  statesLabel?: SecondaryActionState[];
};

type TooltipState = { visible: boolean; content: string };

function PlaylistButton({
  autoAddToPlaylist = false,
  contentID,
  isDisabled = false,
  isInPlaylist,
  statesLabel,
  customClassBadgeV5,
}: PlaylistButtonProps): JSX.Element {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const latestTimeout = useRef<NodeJS.Timeout>(undefined);

  const [tooltipState, setTooltipState] = useState<TooltipState>({
    visible: false,
    content: '',
  });
  const [inPlaylist, setInPlaylist] = useState<boolean>(false);

  const showTooltip = ({ content }: { content: string }) => {
    if (!$_BUILD_RENDERMODE_CSR) {
      if (latestTimeout.current) {
        clearTimeout(latestTimeout.current);
      }

      setTooltipState({ visible: true, content });
      latestTimeout.current = setTimeout(() => {
        setTooltipState((prevState) => ({ ...prevState, visible: false }));
      }, 4000);
    }
  };

  const hasUserDataCollected = useSelector(hasUserDataCollectedSelector);
  const isLoggedIn = useSelector(authenticatedSelector);

  const isInPlaylistAfterAuto = useAutoAddToPlaylist({
    autoAddToPlaylist,
    contentID,
    isDisabled,
    isInPlaylist,
    onDisplayTooltip: showTooltip,
  });

  const sendTrackingOnCTAEvent = useSendTrackingOnCTAEvent();

  useEffect(() => {
    setInPlaylist(isInPlaylistAfterAuto);
  }, [isInPlaylistAfterAuto]);

  const isPlaylistButtonDisabled =
    (isLoggedIn && !hasUserDataCollected) || isDisabled;

  const onPlaylistButtonClicked = () => {
    showTooltip({
      content: !inPlaylist
        ? t('PlaylistButton.added')
        : t('PlaylistButton.removed'),
    });

    try {
      dispatch(
        userListService(
          !inPlaylist ? 'add' : 'remove',
          [contentID],
          'playlist',
        ),
      );
      dispatch(setPersoUpdated(true));

      sendTrackingOnCTAEvent(
        !inPlaylist ? ADDED_TO_PLAYLIST : REMOVED_FROM_PLAYLIST,
      );
    } catch (error) {
      setInPlaylist(isInPlaylist);
      showTooltip({ content: t('PlaylistButton.error') });
    }
  };

  // eslint-disable-next-line react-compiler/react-compiler
  const handleClickDebounced = debounce(onPlaylistButtonClicked, 300);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!hasUserDataCollected) {
      event.stopPropagation();
      return;
    }

    setInPlaylist(!inPlaylist);
    handleClickDebounced();
  };

  const icon = inPlaylist ? (
    <DetailPagePlaylistOnSvg title={t('Icon.playlistOn')} />
  ) : (
    <DetailPagePlaylistOffSvg title={t('Icon.playlistOff')} />
  );

  const stateLabel = statesLabel?.find((elem) => {
    return (
      (inPlaylist && elem.id === PlaylistStateId.InPlaylist) ||
      (!inPlaylist && elem.id === PlaylistStateId.NotInPlaylist)
    );
  });

  if ($_BUILD_RENDERMODE_CSR) {
    return (
      <button
        onClick={handleClick}
        type="button"
        className={classNames(
          styles.PlayListButton,
          stylesDetail['detailV5--focus'],
        )}
        aria-label={stateLabel?.ariaLabel}
        disabled={isPlaylistButtonDisabled}
      >
        <Badge
          customClassBadgeV5={customClassBadgeV5}
          theme="button"
          active={inPlaylist}
          disable={isPlaylistButtonDisabled}
          hasHoverStyle={false}
        >
          {icon}
          <span className={styles.PlayListButton__text}>
            {stateLabel?.label || t('PlaylistButton.buttonLabel')}
          </span>
        </Badge>
      </button>
    );
  }

  return (
    <Tooltip
      isVisible={tooltipState.visible}
      content={
        isPlaylistButtonDisabled
          ? t('PlaylistButton.notAvailableRecoOff')
          : tooltipState.content
      }
      position="top"
      showOnHover={isPlaylistButtonDisabled}
    >
      <ButtonIcon
        disabled={isPlaylistButtonDisabled}
        onClick={handleClick}
        aria-label={stateLabel?.ariaLabel}
        icon={icon}
        label={stateLabel?.label || t('PlaylistButton.buttonLabel')}
      />
    </Tooltip>
  );
}

export default PlaylistButton;
